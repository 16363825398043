<!--
 * @Author: 月魂
 * @Date: 2021-12-21 09:55:18
 * @LastEditTime: 2022-11-12 10:05:42
 * @LastEditors: 月魂
 * @Description: 物华典章
 * @FilePath: \food-show\src\views\Ingredients.vue
-->
<template>
  <div class="box">
    <div class="cont">
      <Navigation :title="this.$t('ingredients.title')" />
      <img src="../assets/temp/ingredients.png" alt="" class="img top-bg">
      <img src="../assets/temp/ingredients-pc.png" alt="" class="img top-bg pc">
      <!-- <img :src="domain + this.$t('ingredients.posterUrl')" alt="" class="img"> -->
      <div class="intro">
        <div class="des" v-for="(item, index) in this.$t('ingredients.des')" :key="index">{{ item }}</div>
      </div>
      <div class="ing-cont">
        <van-tabs v-model="active">
          <van-tab :title="$t('ingredients.ingredients.name')">
            <div class="list">
              <div class="item" v-for="item in this.$t('ingredients.ingredients.list')" :key="item.id" @click="jump('ingredients', item.id)">
                <img :src="domain + item.imgUrl" alt="" class="img">
                <div class="bottom">
                  <div class="title">{{ item.name }}</div>
                  <van-icon name="arrow" size="16" class="icon" />
                </div>
                <!-- <div class="des" v-for="(des, index) in item.des" :key="index">{{ des }}</div> -->
              </div>
            </div>
          </van-tab>
          <van-tab :title="$t('ingredients.specialties.name')">
            <div class="list">
              <div class="item" v-for="item in this.$t('ingredients.specialties.list')" :key="item.id" @click="jump('specialties', item.id)">
                <img :src="domain + item.imgUrl" alt="" class="img">
                <div class="bottom">
                  <div class="title">{{ item.name }}</div>
                  <van-icon name="arrow" size="16" class="icon" />
                </div>
                <!-- <div class="des" v-for="(des, index) in item.des" :key="index">{{ des }}</div> -->
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Tab, Tabs } from 'vant';
import Navigation from '@/components/Navigation'

Vue.use(NavBar).use(Tab).use(Tabs);

export default {
  name: 'Ingredients',
  components: {
    Navigation
  },
  data() {
    return {
      active: 0,
      title: '',
      domain: ''
    }
  },
  mounted() {
    this.title = this.$t('ingredients.title')
    this.domain = this.$t('domain')
    this.wxShare(this.$t('title'), this.$t('ingredients.title'))
  },
  methods: {
    jump(f, s) {
      this.$router.push(`/ingredients/material/${f}/${s - 1}`)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #06062B;
  position: relative;
  padding-bottom: 20px;
  color: #fff;
  .cont {
    max-width: 1200PX;
    margin: auto;
    .top-bg {
      position: absolute;
      top: 0;
      left: 0;
    }
    .pc {
      display: none;
    }
    @media (min-width: 1000PX) {
      .top-bg {
        display: none;
      }
      .pc {
        display: block;
      }
    }
    .intro {
      width: 100%;
      padding: 16px;
      color: #999;
      position: relative;
      z-index: 1;
      @media (min-width: 1000PX) {
        & {
          color: #ccc;
        }
      }
      .des {
        font-size: 12px;
      }
    }
    .ing-cont {
      width: 100%;
      max-width: 1200PX;
      margin: auto;
      padding-top: 24px;
      position: relative;
      z-index: 1;
      .list {
        padding: 16px;
        font-size: 12px;
        .item {
          margin-bottom: 16px;
          border-radius: 15px;
          background: #111135;
          overflow: hidden;
          .img {
            display: block;
            width: 100%;
          }
          .bottom {
            padding: 16px;
            display: flex;
            justify-content: space-between;
            line-height: 20px;
            .title {
              font-size: 14px;
              color: #999;
            }
            .icon {
              color: #999;
              display: flex;
              align-items: center;
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
